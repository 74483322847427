import UserStore, { TUser } from '@/store/UserStore';

import axios from 'axios';

export const setUser = async (payload: TUser) => {
    const { data } = await axios.post('/api/v2/private/user', { ...payload });

    UserStore.setUser({ ...data });
};

export const setUserRef = async ({ initial_refs }: { initial_refs: [string] }) => {
    const { data } = await axios.put('/api/v2/private/user/ref', { initial_refs });

    UserStore.setUser({ ...data });
};

export const updateUser = async (payload: TUser) => {
    const { data } = await axios.put('/api/v2/private/user', { ...payload });

    UserStore.setUser({ ...data });
};
