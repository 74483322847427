import { useCallback, useEffect, useState } from 'react';
import { ConnectedWallet, usePrivy, useWallets } from '@privy-io/react-auth';
import { useSnapshot } from 'valtio';
import AccountStore from '@/store/AccountStore';
import SettingsStore from '@/store/SettingsStore';
import UserStore from '@/store/UserStore';
import { setUser, updateUser } from '@/store/api/user/setUser';
import useConnect from '@/hooks/Vault/useConnect';
import { getUser } from '@/store/api/user/getUser';
import useReferral from '@/hooks/Vault/useReferral';
import { lookUpRefCode } from '@/store/api/user/lookUpRefCode';
import { analytics } from '@/helpers/analytics';

const getTimeZone = () => {
    const offset = new Date().getTimezoneOffset();
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    const sign = offset <= 0 ? '+' : '-';
    const formattedTimezone = `GMT${sign}${hours}:${minutes.toString().padStart(2, '0')}`;

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return `${timezone}; ${formattedTimezone}`;
};

const useAuthConnect = () => {
    const { login, user, ready, isModalOpen } = usePrivy();
    const { accountAddress, accountIsLoading, signer } = useSnapshot(AccountStore.state);
    const { activeChain } = useSnapshot(SettingsStore.state);
    const { userIsSet } = useSnapshot(UserStore.state);
    const { wallets } = useWallets();
    const [connect] = useConnect();
    const stableConnect = useCallback(connect, []);
    const [fetchFailed, setFetchFailed] = useState(false);

    // Capture and store referral code, if any
    useReferral();

    const handleConnect = useCallback(
        async (connectWallets: ConnectedWallet[]) => {
            AccountStore.setAccountIsLoading(true);
            await stableConnect(connectWallets, activeChain);
        },
        [activeChain],
    );

    const handleLogin = useCallback(async () => {
        if (!user && ready && !isModalOpen) login();
    }, [user, ready, isModalOpen, login]);

    // Handle connection and user data fetching
    useEffect(() => {
        const embeddedWallet = wallets.find((wallet) => wallet.walletClientType === 'privy');

        if (
            user &&
            embeddedWallet &&
            !accountAddress &&
            !accountIsLoading &&
            activeChain &&
            !userIsSet &&
            !fetchFailed
        ) {
            const handleConnect = async () => {
                AccountStore.setAccountIsLoading(true);

                try {
                    let getUserData;

                    if (user?.wallet?.address) {
                        try {
                            getUserData = await getUser();

                            if (!getUserData.timezone) {
                                await updateUser({
                                    smart_wallet_address: getUserData.smart_wallet_address,
                                    timezone: getTimeZone(),
                                });
                            }

                            analytics({
                                type: 'otherEvent',
                                name: 'user_login',
                                params: {
                                    smart_wallet_address: getUserData.smart_wallet_address,
                                    source_page: window.location.pathname,
                                },
                            });

                            UserStore.setUserIsSet(true);
                        } catch (error) {
                            // @ts-ignore
                            if (error?.response?.status === 404) {
                                console.warn('User not found, proceeding with default connect.');
                            } else {
                                console.error('Failed to fetch user:', error);
                                setFetchFailed(true);
                            }
                        }
                    }

                    if (getUserData) {
                        await stableConnect(wallets, activeChain, getUserData.smart_wallet_address);
                        UserStore.setUserIsSet(true);
                    } else {
                        await stableConnect(wallets, activeChain);
                    }
                } catch (error) {
                    console.error('Failed to fetch user or connect:', error);
                } finally {
                    AccountStore.setAccountIsLoading(true);
                }
            };

            handleConnect();
        }
    }, [user, accountAddress, accountIsLoading, wallets, stableConnect, activeChain]);

    // Effect to set the user data
    useEffect(() => {
        const fetchUser = async (payload: any) => {
            await setUser(payload);
            UserStore.setUserIsSet(true);

            analytics({
                type: 'otherEvent',
                name: 'user_login',
                params: {
                    smart_wallet_address: String(payload.smart_wallet_address),
                    source_page: window.location.pathname,
                },
            });

            window?.cookie3?.trackEvent({
                category: 'Conversion',
                action: 'Registration',
                name: 'User Sign Up Complete',
                value: 1,
            });

            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            const params = url.searchParams;

            params.delete('ref');
            const newUrl = `${url.pathname}?${params.toString()}`;

            window.history.replaceState(null, '', newUrl);
        };

        const checkAndSetReferral = async (payload: any) => {
            const referralCode = localStorage.getItem('ref');

            if (referralCode && !payload.referred_by) {
                const verifiedReferralUserId = await lookUpRefCode(referralCode);

                if (verifiedReferralUserId) {
                    payload.referred_by = verifiedReferralUserId;
                    // Remove ref code from localStorage after successful verification
                    localStorage.removeItem('ref');
                }
            }
            fetchUser(payload);
        };

        if (accountAddress && !userIsSet) {
            const linkedAccounts: any = user?.linkedAccounts?.find(
                (account) => account && account.type === 'wallet' && account.walletClientType === 'metamask',
            );

            const payload: any = {
                smart_wallet_address: accountAddress,
                google_email: user?.google?.email,
                email: user?.email?.address,
                telegram_id: user?.telegram?.telegramUserId,
                timezone: getTimeZone(),
                platform_name: window?.location?.pathname === '/join' ? 'whitelist' : 'yellow',
            };

            if (linkedAccounts?.address && user?.discord?.username) {
                payload.discord_username = user.discord.username;
                payload.discord_id = user.discord.subject;
                payload.external_wallet_address = linkedAccounts.address;
            } else if (linkedAccounts?.address) {
                payload.external_wallet_address = linkedAccounts.address;
            } else if (user?.discord?.username) {
                payload.discord_username = user.discord.username;
                payload.discord_id = user.discord.subject;
            }

            checkAndSetReferral(payload);
        }
    }, [accountAddress, userIsSet, user, signer]);

    return {
        handleLogin,
        accountLoading: isModalOpen,
        user,
        accountAddress,
        wallets,
        handleConnect,
        ready,
    };
};

export default useAuthConnect;
