import UserStore from '@/store/UserStore';

import axios from 'axios';

export const getUser = async () => {
    const { data } = await axios.get('/api/v2/private/user');

    UserStore.setUser({ ...data.user });
    UserStore.setReferredCount(data.referred_count);

    return data.user;
};
