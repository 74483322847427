import axios from 'axios';

export const lookUpRefCode = async (referralCode: string): Promise<string | null> => {
    try {
        const { data: referralUser } = await axios.get(`/api/v2/private/user/ref?ref=${referralCode}`);

        if (referralUser) {
            return referralUser?.id;
        }
    } catch (error) {
        console.error('Failed to verify referral code:', error);
        return null;
    }
    return null;
};
