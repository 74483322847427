'use client';

import React, { ComponentType, Suspense } from 'react';

import { InitSubscriptionProvider } from '@/providers/InitSubscription';
import useAuthConnect from '@/hooks/Vault/useAuthConnect';

interface WithAuthProps {}

export function withAuth<T extends WithAuthProps>(WrappedComponent: ComponentType<T>, InSuspense?: boolean) {
    const WithAuth: React.FC<T> = props => (
        <InitSubscriptionProvider>{renderWrappedComponent(props)}</InitSubscriptionProvider>
    );

    function renderWrappedComponent(props: T) {
        if (InSuspense) {
            return (
                <Suspense fallback={<div>Loading...</div>}>
                    <WrappedComponentInner {...props} />
                </Suspense>
            );
        }
        return <WrappedComponentInner {...props} />;
    }

    function WrappedComponentInner(props: T) {
        useAuthConnect();

        return <WrappedComponent {...props} />;
    }

    return WithAuth;
}
