import { getPublicConfig } from 'configs/app';
import { useRouter } from 'next/router';
import { getConfigs } from 'web-sdk/configs/app';
import { DEFAULT_LANG } from 'web-sdk/constants';
import fallbackLocales from '@/configs/fallback-locales.json';
import { getLocaleWithFallback } from '@/helpers/getLocaleWithFallback';

export interface LocaleRelativePathParam {
    locale: string;
    relativePath: string;
}

export const getAlternateLink = (relativePath: LocaleRelativePathParam[] | string) => {
    const supportLocales = [...getConfigs().languages, ...Object.keys(fallbackLocales)].map(l => l.toLowerCase());
    const rootUrl = getPublicConfig().rootUrl || '';
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { defaultLocale } = useRouter();

    const resultRootUrl = rootUrl.endsWith('/') ? rootUrl.replace(/\/$/, '') : `${rootUrl}`;

    const getResultRelativePath = (relativePath: string) => {
        return relativePath.startsWith('/') ? relativePath.replace(/^\//, '') : relativePath;
    };

    const getResultAbsoluteUrl = (
        relativePath: string,
        locale: string,
        defaultLocale: string,
        resultRelativePath: string,
    ) => {
        return relativePath
            ? (relativePath === '/'
                  ? resultRootUrl + ((locale !== defaultLocale && `/${locale}`) || '')
                  : locale !== defaultLocale && `${resultRootUrl}/${locale}/${resultRelativePath}`) ||
                  `${resultRootUrl}/${resultRelativePath}`
            : resultRootUrl + ((locale !== defaultLocale && `/${locale}`) || '');
    };

    if (typeof relativePath === 'string') {
        const resultRelativePath = getResultRelativePath(relativePath);
        const resultDefaultAbsoluteUrl = relativePath
            ? relativePath === '/'
                ? resultRootUrl
                : `${resultRootUrl}/${resultRelativePath}`
            : resultRootUrl;

        return [
            ...supportLocales.map((locale, index) => {
                const resultAbsoluteUrl = getResultAbsoluteUrl(
                    relativePath,
                    getLocaleWithFallback(locale).toLowerCase(),
                    (defaultLocale || DEFAULT_LANG).toLowerCase(),
                    resultRelativePath,
                );

                return <link key={index} rel="alternate" hrefLang={locale.toLowerCase()} href={resultAbsoluteUrl} />;
            }),
            <link key="x-default" rel="alternate" hrefLang="x-default" href={resultDefaultAbsoluteUrl} />,
        ];
    } else {
        const defaultRelativePath = relativePath.find(r => r.locale === defaultLocale)?.relativePath || '';
        const resultDefaultRelativePath = defaultRelativePath.startsWith('/')
            ? defaultRelativePath.replace(/^\//, '')
            : defaultRelativePath;
        const resultDefaultAbsoluteUrl = defaultRelativePath
            ? defaultRelativePath === '/'
                ? resultRootUrl
                : `${resultRootUrl}/${resultDefaultRelativePath}`
            : resultRootUrl;

        return [
            ...supportLocales.map((locale, index) => {
                const localeRelativePath =
                    relativePath.find(r => r.locale.toLowerCase() === locale.toLowerCase())?.relativePath || '';

                if (!localeRelativePath) {
                    return null;
                }

                const resultLocaleRelativePath = getResultRelativePath(localeRelativePath);
                const resultAbsoluteUrl = getResultAbsoluteUrl(
                    localeRelativePath,
                    getLocaleWithFallback(locale).toLowerCase(),
                    (defaultLocale || DEFAULT_LANG).toLowerCase(),
                    resultLocaleRelativePath,
                );

                return <link key={index} rel="alternate" hrefLang={locale.toLowerCase()} href={resultAbsoluteUrl} />;
            }),
            <link key="x-default" rel="alternate" hrefLang="x-default" href={resultDefaultAbsoluteUrl} />,
        ].filter(Boolean);
    }
};
