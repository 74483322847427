'use client';

import { useEffect } from 'react';

const useReferral = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            // Use the native URLSearchParams to get the 'ref' parameter
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            const referralCode = url.searchParams.get('ref');

            if (referralCode) {
                // Store the referral code in localStorage
                localStorage.setItem('ref', referralCode);
            }
        }
    }, []); // Empty dependency array ensures this only runs once on mount
};

export default useReferral;
