import { Decimal } from '@/web-sdk/components/Decimal';
import { removeTrailingFloatZero } from './removeTrailingFloatZero';

export const renderPrice = (price: number) => {
    if (isNaN(price) || price === null) {
        return '-';
    }

    return +price < 0.01
        ? removeTrailingFloatZero(Decimal.format(String(price), 8, ','))
        : +price < 1
          ? removeTrailingFloatZero(Decimal.format(String(price), 6, ','))
          : removeTrailingFloatZero(Decimal.format(String(price), 2, ','));
};
