export const HOST_URL = ''
export const PRE_BUILD_ASSETS = ['btc', 'usdt']
export const DEFAULT_MARKET_DISPLAY_LIMIT = 5
export const DEFAULT_CONTRACT_DISPLAY_LIMIT = 2
export const DEFAULT_SOCIAL_DISPLAY_LIMIT = 4
export const DEFAULT_TICKER = {
  last: 0,
  volume: 0,
  price_change_percent: '+0.00%',
}
export const TOKEN_PRICE_TOOLTIP_URL = '/learn/market_price'
export const TOKEN_VOLUME_TOOLTIP_URL = '/learn/volume'
export const TOKEN_MARKET_CAP_TOOLTIP_URL = '/learn/market_capitalization'
export const TOKEN_SUPPLY_TOOLTIP_URL = '/learn/circulating_supply'
export const ASSETS_PER_DESKTOP_PAGE = 50
export const ASSETS_PER_MOBILE_PAGE = 30
export const POSTS_PER_PAGE = 10
export const TAG_ARTICLES_PER_PAGE = 5
export const MAX_ALLOWED_CHAR_COUNT = 30000
export const MAX_ALLOWED_CHAR_PREVIEW = 1930
export const MAX_TITLE_CHAR_COUNT = 90
export const DEFAULT_LOCALE = 'en'
