import { getPublicConfig } from 'configs/app'
import { DEFAULT_LOCALE } from '../constants'
import { useRouter } from 'next/router'

export const getAbsoluteUrl = (relativePath: string, currentLocale?: string): string => {
  let locale = DEFAULT_LOCALE

  if (currentLocale) {
    locale = currentLocale
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const router = useRouter()

    locale = router.locale || DEFAULT_LOCALE
  }

  let rootUrl = getPublicConfig().rootUrl || ''

  if (rootUrl.endsWith('/')) {
    rootUrl = rootUrl.replace(/\/$/, '')
  }

  if (locale !== DEFAULT_LOCALE) {
    rootUrl = `${rootUrl}/${locale.toLowerCase()}`
  }

  if (relativePath.startsWith('/')) {
    relativePath = relativePath.replace(/^\//, '')
  }

  const resultAbsoluteUrl = relativePath ? 
    (relativePath === '/' ? rootUrl : `${rootUrl}/${relativePath}`)
    : rootUrl

  return resultAbsoluteUrl
}
